import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"
import GoogleReviewsBox from "./GoogleReviewsBox"
import InlineFormWithEmailField from "./InlineFormWithEmailField"

const InnerHeader = ({
  title,
  titleEC,
  description,
  descriptionEC,
  googleReviewText,
  isTopImage,
  formDetails,
  isSearchBox,
  setSearchQuery, // Add this prop
}) => {
  return (
    <div className="bg-primary-light relative lg:mb-28">
      <div className={`cc-section--horizontal-space cc-section--vertical-space ${isSearchBox === true ? "!pb-0 md:!pb-0" : " !pb-16 md:!pb-24"}`}>
        <div className={`max-w-[800px]`}>
          {isTopImage !== false ? (
            <ImageRenderer
              img={`section--title-image`}
              alt={`section--title-image`}
            />
          ) : (
            ""
          )}
          <h2
            className={`heading-text-highligted-inherit mt-5 mb-3 text-[#25282C] text-[26px] font-[600] md:text-[36px] leading-[1.4em] ${titleEC}`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className={`text-[20px] md:text-[24px] text-[#000000] ${descriptionEC}`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {isSearchBox === true ? (
            <div className="pt-0 lg:pt-12 pb-16 lg:pb-0 relative top-[23px]">
              <InlineFormWithEmailField
                formFieldIcon={formDetails.formFieldIcon}
                formFieldPlaceholderText={formDetails.formFieldPlaceholderText}
                buttonText={formDetails.buttonText}
                setSearchQuery={setSearchQuery} // Pass the setSearchQuery prop
                isSearchBox={isSearchBox} // Pass the isSearchBox prop
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="lg:absolute mt-16 lg:mt-0 max-w-[420px] w-full right-[40px] top-[110px]">
          <GoogleReviewsBox text={googleReviewText} />
        </div>
      </div>
    </div>
  )
}

export default InnerHeader
