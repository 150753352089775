import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

const GoogleReviewsBox = ({ text, textEC }) => {
  return (
    <div className="relative">
      <div className="absolute w-full h-full z-[1] top-[-30px]">
        <ImageRenderer
          img={`inner-header--bg-semi-circle-image`}
          alt={`inner-header--bg-semi-circle-image`}
        />
      </div>
      <div className="max-w-[270px] md:max-w-[320px] mx-auto relative z-[2]">
        <div className="google-review-box bg-white rounded-tl-[16px] rounded-tr-[16px] rounded-bl-[16px] shadow pt-[50px] pb-[20px] px-5">
          <div className="text-center bg-white w-[100px] h-[100px] rounded-[50%] flex items-center justify-center mx-auto absolute top-[-50px] left-1/2 transform -translate-x-1/2">
            <ImageRenderer img={`google-icon`} alt={`google-icon`} />
          </div>
          <div className="mt-[-20px]">
            <ImageRenderer
              img={`inner-header--arrow-icon`}
              alt={`inner-header--arrow-icon`}
            />
          </div>
          <div className="text-center">
            <span className="font-ubuntu text-[12px] text-[#828282] block">
              Customer Reviews
            </span>
            <div className="flex justify-center items-center gap-[15px]">
              <span className="font-ubuntu text-secondary font-[500] text-[25px]">
                5.0
              </span>
              <div className="flex gap-[2px]">
                <FontAwesomeIcon icon={faStar} className="text-[#FCE300] text-[20px]" />
                <FontAwesomeIcon icon={faStar} className="text-[#FCE300] text-[20px]" />
                <FontAwesomeIcon icon={faStar} className="text-[#FCE300] text-[20px]" />
                <FontAwesomeIcon icon={faStar} className="text-[#FCE300] text-[20px]" />
                <FontAwesomeIcon icon={faStar} className="text-[#FCE300] text-[20px]" />
              </div>
            </div>
          </div>
          <p className={`text-[16px] leading-[1.2em] text-[#25282C] max-w-[240px] mx-auto mt-4 text-center ${textEC}`}>
            {text}
          </p>
        </div>
      </div>
    </div>
  )
}

export default GoogleReviewsBox
