import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHeader from "../components/molecules/InnerHeader"
import ContentSection from "../components/molecules/ContentSection"
import { termsandconditionsPage } from "../content/TermsAndConditions"

const TermsAndConditions = () => (
  <Layout>
    <Seo title="Terms & Conditions" />

    <InnerHeader
      title={termsandconditionsPage.title}
      googleReviewText={termsandconditionsPage.googleReviewText}
      description={termsandconditionsPage.description}
    />

    <div className="cc-section--horizontal-space pt-12 lg:pt-2">
      {termsandconditionsPage.contentSections.map((section, index) => (
        <ContentSection
          key={index}
          title={section.title}
          titleEC={section.titleEC}
          description={section.description}
          list={section.list}
          image={section.image}
          imageEC={section.imageEC}
        />
      ))}
    </div>
  </Layout>
)

export default TermsAndConditions
